.header {
  height: 10vh;
  color: dodgerblue;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid gainsboro;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  width: 100%;
}
.header-contents {
  width: 94%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.header h1 {
  margin: 0;
}
.search-bar {
  background-color: inherit;
  border: solid 1px gainsboro;
  border-radius: 10px;
  width: 40%;
  height: 50%;
  color: grey;
  padding: 0%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.search-bar-focus {
  background-color: inherit;
  border: solid 2px grey;
  border-radius: 10px;
  width: 40%;
  height: 50%;
  color: grey;
  padding: 0%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.search-input {
  background-color: inherit;
  border: none;
  width: 80%;
  height: 100%;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0%;
}
.side-nav-menu {
  /* width: 2%; */
  margin-right: 2%;
  /* position: fixed;
  left: 50px; */
  color: dodgerblue;
}
.search-input:focus {
  outline: 0;
  border: none;
}
.search-input:focus {
  outline: 0;
  border: none;
}
.search-input::placeholder {
  color: rgb(48, 48, 48);
}
.search-submit {
  width: 20%;
  height: 100%;
}
.mini-profile {
  width: fit-content;
  border: none;
  padding: 0%;
  height: 60%;
  border-radius: 50%;
  background-color: inherit;
}
.user-icon {
  margin: 0%;
  padding: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* width: 30%; */
  aspect-ratio: 1;
  background-color: black;
  color: white;
  min-width: 35px;
}
.user-icon h4 {
  margin: 0%;
}
a {
  text-decoration: none;
  color: inherit;
}
.drawer {
  width: 50%;
}
@media screen and (max-width: 500px) {
  .header {
    height: 60px;
  }
  .search-bar {
    width: 70%;
  }
  .search-bar-focus {
    width: 70%;
  }
  .search-input {
    width: 80%;
  }
}
