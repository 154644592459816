.upload-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.upload-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.upload-container {
  height: 20vh;
  width: 200px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  margin: 3vh;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid dodgerblue;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  margin-top: 30px;
}
.text-filled {
  width: 80%;
}
.upload-btn {
  margin-top: 10px;
}
.title-input {
  width: 60%;
  margin-bottom: 20px;
}
.description-input {
  width: 90%;
}
.upload-container {
  margin: 5px;
}
.video-upload-container {
  width: 44%;
}
.thumbnail-upload-container {
  width: 56%;
}
.upload-content-container {
  width: 96%;
  margin-bottom: 20px;
}
.ref-input {
  width: 90%;
  margin-top: 20px;
}
.row-upload {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.category-container {
  width: 30%;
  height: fit-content;
}
.country-education {
  margin-top: 10px;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.premium-content-container{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
}
.level-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 30%;
}

@media screen and (max-width: 500px) {
  .title-input {
    width: 100%;
    margin-bottom: 20px;
  }
  .description-input {
    width: 90%;
  }
  .upload-container {
    margin: 5px;
  }
  .video-upload-container {
    width: 44%;
  }
  .thumbnail-upload-container {
    width: 56%;
  }
  .upload-content-container {
    width: 96%;
    display: flex;
    align-items: center;
    height: 170px;
  }
  .ref-input {
    width: 90%;
    margin-top: 20px;
  }
  .row-upload {
    width: 90%;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
  }
  .category-container {
    width: 100%;
    height: fit-content;
  }
}
