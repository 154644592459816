.about-page {
  width: 94%;
  margin-right: 2%;
  margin-left: 2%;
  margin-top: 20px;
  padding-left: 1%;
  padding-right: 1%;
  border: 2px solid gainsboro;
  border-radius: 20px;
  padding-top: 40px;
}

.about-container {
  text-align: justify;
}

.profiles {
  width: 100%;
  height: 20vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.profile {
  /* border: 1px solid black; */
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.avatar {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background-color: gainsboro;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.copyright-container {
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-image-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .profiles {
    flex-direction: column;
    align-items: center;
    height: 90vh;
  }
  .profile {
    width: 100%;
    /* flex-direction: row;
    justify-content: space-around; */
  }
  .avatar {
    width: 120px;
    height: 120px;
  }
  .btn {
    width: 80%;
  }
}
