.video-play-page {
  height: 100%;
  width: 100%;
  display: flex;
  text-align: left;
  padding-bottom: 5vh;
  padding-top: 20px;
}
.video-content-container {
  width: 60%;
  padding-left: 20px;
  padding-right: 30px;
  /* max-height: 70vh; */
}
.video-container {
  width: 100%;
  display: flex;
  height: 60vh;
}
.video-container:hover {
  opacity: 0.85;
  transition: opacity 0.2s ease-in-out;
}

.fullscreen-enabled .video-container {
  height: auto;
  position: relative;
  aspect-ratio: 1;
}
.content-container {
  height: fit-content;
  min-height: 10vh;
  position: relative;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
}
.similar-content {
  width: 35%;
  /* border: solid 1px purple; */
  margin-left: 5%;
  margin-right: 30px;
  padding-left: 30px;
  border-radius: 20px;
  background-color: rgb(233, 233, 233);
  text-align: center;
}
.similar-content-title {
  margin: 0%;
}
.min-video-card {
  height: 95px;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.tiny-video-thumbnail-container{
  width: 170px;
  height: 95px;
  position: relative;
}
.tiny-video-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: gainsboro;
  border-radius: 5px;
}
.thumbnail-icon-container{
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
}
.tiny-description {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 8px;
  padding-right: 8px;
}
.tiny-title {
  margin-bottom: 5px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.play {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.player {
  min-height: 70vh;
}
.maximize {
  position: absolute;
  bottom: 10px;
  right: 10px;
  /* position: absolute
  right: 5px; */
}
.video-discussion {
  width: 94%;
  padding-top: 10px;
  padding-bottom: 40px;
  padding-left: 3%;
  padding-right: 3%;
  background-color: rgba(10, 20, 30, 0.1);
  border-radius: 10px;
}
.video-discussion-hidden {
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  /* width: 94%; */
  padding-top: 10px;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 40px;
  border-radius: 10px;
  background-color: rgba(10, 20, 30, 0.1);
}
.read-more-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.description-display {
  white-space: pre-wrap;
  margin: 5px 0 0 0;
}
.description-container {
  border-radius: 10px;
  background-color: gainsboro;
  padding: 20px;
  margin-bottom: 10px;
}
.description-container h4 {
  margin: 0;
}
.ref-display {
  white-space: pre-wrap;
  overflow: hidden;
  border-radius: 10px;
  background-color: gainsboro;
  padding: 20px;
  padding-top: 0;
}
.ref-link {
  color: dodgerblue;
}
.card-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.stats {
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tools {
  width: 55%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.ad-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: gainsboro 2px solid;
  border-radius: 10px;
}
.follow-container {
  background-color: dodgerblue;
  height: 30px;
  width: fit-content;
  border-radius: 15px;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 80px;
  border: none;
}
.unfollow-container {
  background-color: black;
}
.likes-count {
  background-color: red;
  border-radius: 20px;
  padding: 0px 10px 2px 10px;
  color: white;
}
.views-count {
  background-color: dodgerblue;
  border-radius: 20px;
  padding: 0px 10px 2px 10px;
  color: white;
}
@media screen and (max-width: 900px) {
  .header {
    height: 60px;
  }
  .video-play-page {
    display: flex;
    flex-direction: column;
  }
  .video-content-container {
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
  }
  .video-container {
    height: fit-content;
    width: 100%;
  }
  .content-container {
    min-height: 10vh;
  }
  .similar-content {
    /* width: 100%; */
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    margin: 0;
    margin-top: 10px;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
  }

  .comments-container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .card-actions {
    flex-direction: column;
    justify-content: space-around;
  }
  .description-container {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }
  .min-video-card {
    height: 120px;
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .stats {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .tools {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .expand-container {
    margin: 0;
  }
}
.player {
  width: 100%;
  height: 100%;
}
.comments {
  display: flex;
  flex-direction: column;
  background-color: gainsboro;
  border-radius: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
}
.comment {
  /* border: 1px solid dodgerblue; */
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.comment-text {
  margin-left: 15px;
}
