.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 40px;
}
.loading {
  margin-top: 40vh;
}
.login-container {
  height: 60vh;
  width: 80vw;
  max-width: 350px;
  border: solid 1px gray;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.sigin-container {
  height: 70vh;
}
.signup-container {
  margin-top: 40px;
  height: 20vh;
  width: 80vw;
  max-width: 350px;
  border: solid 1px gray;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.username {
  width: 80%;
}
.password {
  width: 80%;
}
.confirm-password {
  width: 80%;
}
.sign-up-btn {
  width: 80%;
}
.log-in-btn {
  width: 80%;
}
.inputs-container {
  height: 35%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.complete-profile-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 4vh;
}
.complete-profile-container {
  height: 70vh;
  width: 80vw;
  max-width: 350px;
  border: solid 1px gray;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.country-select {
  width: 90%;
  height: 30px;
  /* border-color: dodgerblue; */
}
.region-select {
  width: 90%;
  height: 30px;
}
.age {
  width: 88%;
  height: 25px;
}
.date-input {
  width: 100%;
}
.react-datepicker-wrapper {
  width: 90%;
}
.password-reset-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 10%;
}
.password-reset-container {
  height: 40vh;
  width: 80vw;
  max-width: 350px;
  border: solid 1px gray;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
