.profile-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
}

.profile-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px gainsboro solid;
  padding: 30px;
  border-radius: 10px;
}
.profile-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.profile-content-text {
  display: flex;
  flex-direction: column;
}
.photo-container {
  width: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-card {
  /* width: 100%; */
  height: 40px;
  border: 1px solid gainsboro;
  display: flex;
  flex-direction: row;
  border-radius: 25px;
  justify-content: space-around;
  align-items: center;
  text-align: left;

  padding-left: 30px;
  padding-right: 30px;

  /* margin-top: 2vh;
  margin-bottom: 2vh; */
  text-align: left;
}
.profile-display-name {
  /* height: 20vh; */
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: dodgerblue;
}
.label {
  margin-right: 20px;
}
.change-btn {
  width: 300px;
}
.monetize-container {
  width: 40%;
  border: 1px gainsboro solid;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.account-card {
  border: 1px solid gainsboro;
  max-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-height: 50px;
  border-radius: 10px;
}
.followers-following-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: left;
  border-top: 1px solid gainsboro;
  margin-top: 10px;
}
.followers {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  overflow-y: scroll;
  max-height: 40vh;
}
@media screen and (max-width: 500px) {
  .profile-content {
    flex-direction: column;
  }

  .profile-card {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .profile-container {
    flex-direction: column;
  }
  .profile-content-container {
    min-height: fit-content;
    border: none;
    padding: 0;
  }
  .monetize-container {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    border: none;
  }
  .change-btn {
    width: fit-content;
  }
}
