.subscribe-page {
  /* margin-top: 20vh; */
  height: 90vh;
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.subscribe-page-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid gainsboro;
  width: 30vw;
  min-width: 400px;
  height: 50vh;
  min-height: 400px;
}
/* .subscribe-container{
  width: fit-content;

} */
.content-iframe {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}
.content-iframe-loading {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}
.name-logo {
  display: flex;
  align-items: center;
  /* justify-content: ; */
}
.phone-number-input {
  max-width: 350px;
}
.subscribe-btn {
  max-width: 350px;
}
.confirm-btn {
  max-width: 350px;
}
@media screen and (max-width: 600px) {
  .subscribe-page {
    height: 90vh;
    width: 90vw;
  }
}
