.new-school-page {
  /* margin-top: 20vh; */
  width: 40%;
  margin-left: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.logo-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.school-name-input {
  width: 100%;
}
.new-school-inputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}
.subject-checkboxes-container {
  margin-bottom: 20px;
}
.subject-checkboxes {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 600px) {
  .new-school-page {
    width: 90%;
    margin-left: 5%;
  }
}
