.school-page {
  width: 100%;
  min-height: 90vh;
  height: fit-content;
  display: flex;
  flex-direction: row;
}
.school-page-side-nav {
  width: 20%;
  border-right: 1px solid gainsboro;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}
.school-page-side-nav-btns {
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.school-page-content {
  width: 90%;
  padding-left: 20px
}
.members {
  height: 80%;
  display: flex;
  flex-direction: column;
}
.school-videos {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 90vh;
}
.name-logo{
  display: flex;
  flex-direction: row;
}
