.tabs {
  display: flex;
  justify-content: space-around;
}
.tabs button {
  font-size: larger;
}
.not-active-tab {
  border: none;
  color: black;
  background-color: inherit;
  border-bottom: solid 2px white;
}
.active-tab {
  border: none;
  color: dodgerblue;
  background-color: inherit;
  border-bottom: solid 2px dodgerblue;
  /* background-color: white; */
}
.channel-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 5%;
  margin-right: 5%;
}
.text-filled {
  width: 80%;
}
.upload-btn {
  margin-top: 10px;
}
.playlist-card {
  width: 21%;
  height: 280px;
  margin: 15px;
}
.video-card-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  
  height: fit-content;
  margin-left: 5px;
  margin-right: 5px;
}
.my-channel-videos{
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 500px) {
  .playlist-card {
    width: 96%;
    margin: 0;
    margin-left: 2%;
  }
  .channel-content {
    margin: 0%;
  }
  .channel-page h3 {
    margin: 0;
  }
}
