.home-page {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  min-height: 90vh;
  position: fixed;
}
.home-page::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}
.side-nav {
  padding-top: 2%;
  width: 80px;
  z-index: 100;
  background-color: #edebf1;
  position: fixed;
  height: 90vh;
  border-right: 1px solid gainsboro;
  border-top: 1px solid gainsboro;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-width: 100px; */
}
.side-nav-item {
  margin: 0 0 30px 0;
}
.home-page-content {
  width: 100%;
  position: relative;
  left: 80px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
}
.content {
  padding-left: 3%;
  width: 90%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

.video-card {
  width: 300px;
  min-width: 300px;
  height: 280px;
  padding: 0 12px 24px;
}
.video-title {
  margin: 0.75rem 0 0.1rem;
  font-weight: 600;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.card-details {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.video-card h4 {
  margin: 0%;
}
.short-description {
  width: 100%;
  height: 30%;
  text-align: justify;
}
.thumbnail-container{
  width: 100%;
  height: 65%;
  position: relative;
}
.video-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.8rem;
  background-color: gainsboro;
  position: absolute;
}
.thumbnail-icon-container{
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
}
.mobile-video-card-container{
 margin-bottom: 10px;
 border: 1px solid gainsboro;
 border-radius: 10px;
 /* border-top: 1px solid gainsboro; */
 width: 100%;
}
.mobile-video-card{
  width: 100%;
  height: 80px;
  display: flex;
  margin-bottom: 4px;
}
.mobile-video-thumbnail{
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* margin-left: 5px; */
  margin-right: 10px;
  border-radius: 0.2rem;
  background-color: gainsboro;
}
.mobile-card-details{
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 60%;
}
.mobile-videos {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  max-height: 350px;
  overflow-x: scroll;
}
.mobile-views-likes{
  font-size:xx-small;
}
.mobile-video-card-image{
  width: 40%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
}
/* .mobile-video-title{

} */
.scroll-btn{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid dodgerblue;
  display: flex;
  justify-content: center;
  align-items: center;
  position:absolute;
  /* transform: translateY(-50%); */
  bottom: 110px;

}
.scroll-right{
  right: 0;
}
.scroll-left{
  left: 0;
}
.shadow {
  margin-left: 50%;
  width: 50%;
  height: 100%;
  color: white;
  background-color: rgba(20, 20, 20, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.creator-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.creator-profile-btn {
  border: none;
  background: inherit;
}
.creator-name {
  font-size: 0.9rem;
  font-weight: 300;
}
.chips {
  display: flex;
  padding-top: 5px;
  background-color: white;
  border-bottom: 1px solid gainsboro;
  overflow-x: scroll;
  width: 100%;
  min-height: 30px;
  padding-left: 1%;
}
.topic-chip {
  margin: 0 0 0 2rem;
}
::-webkit-scrollbar {
  height: 8px; /* height of horizontal scrollbar ← You're missing this */
  width: 8px; /* width of vertical scrollbar */
}
.row {
  padding: 10px 0 10px 0;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  border-bottom: 1px solid gainsboro;
  display: flex;
  flex-direction: column;
}
.topic-name {
  text-align: left;
  width: 100%;
}
.topic-name h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.videos {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
}
.name-date {
  display: flex;
  flex-direction: column;
}
.create-date {
  font-size: small;
}
.views-likes {
  display: flex;
}
.card-views {
  background-color: dodgerblue;
  border-radius: 10px;
  padding: 1px 10px 2px 10px;
  color: white;
  font-size: small;
  margin-right: 5px;
}
.card-likes {
  background-color: red;
  border-radius: 10px;
  padding: 1px 10px 2px 10px;
  color: white;
  font-size: small;
}
.channels-container {
  padding-top: 5px;
  border-bottom: 1px solid gainsboro;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  width: 100%;
  padding-left: 5px;
}
.fixed {
  position: relative;
  background-color: white;
}
.channel-avatar {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80px;
}
.school-name {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8rem;
}
.videos-country {
  display: flex;
  flex-direction: row;
}
.country-icon-container {
  width: 15px;
}
.avatar-container {
  position: relative;
}
.locked-icon-container {
  background-color: dodgerblue;
  border-radius: 50%;
  width: 40%;
  height: 40%;
  aspect-ratio: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.school-videos-count {
  background-color: purple;
  color: white;
  border-radius: 6px;
  font-size: x-small;
  width: 20px;
  height: fit-content;
  text-align: center;
  padding-left: 2px;
  padding-right: 2px;
}
.school-page-side-nav {
  min-width: 250px;
}
.subject-channels-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  width: 100%;
  padding-left: 5px;
}

@media screen and (max-width: 600px) {
  .home-page {
    width: 100%;
    padding: 0%;
  }
  .home-page-content {
    left: 0;
  }
  .content {
    padding-left: 0;
    width: 100%;
  }
  .row {
    width: 100%;
    padding: 0px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .videos {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .videos .video-card {
    width: 96%;
    height: 250px;
    border: none;
  }
  .video {
    height: 75%;
  }
  .topic-name {
    margin-left: 20px;
  }
  .chips {
    width: 100%;
    left: 0;
  }
  .school-page {
    flex-direction: column;
  }
  .school-page-side-nav {
    /* flex-direction: row; */
    padding-top: 10px;
    border: none;
    width: 90%;
  }
  .school-page-side-nav-btns {
    /* justify-content: space-between; */
    flex-direction: row;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .content {
    padding-left: 0;
    width: 100%;
  }
  .row {
    width: 100%;
    padding: 0px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
 
  .video-card {
    width: 40%;
    height: 250px;
    border: none;
  }
  .video {
    height: 75%;
  }
  .topic-name {
    margin-left: 20px;
  }
  .chips {
    width: 100%;
    left: 0;
  }
}
